.places-container {
    position: absolute;
    z-index: 98;
    background-color: #fff;
    max-height: 270px;
    overflow: auto;
    padding: 10px 20px;
    border: 1px solid #ced4da;
    width: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

ul.places-container li {
    list-style: none;
}