.order-detail-view .card {
    height: 100%;
}

.order-detail-view .main-block .col-6{
    padding: 10px;
}

.order-detail-view .card .card-header{
    background-color:#FFF;
    font-weight: bold;
}